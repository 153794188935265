<template>
  <vx-card v-show="!loading">
    <vs-row>
      <h4 class="mb-5">Edit Place</h4>
    </vs-row>

    <vs-row vs-w="12">
      <vs-col vs-w="12">
        <vs-row vs-w="12">
          <vs-col vs-w="12" class="mb-3">
            <span class="mb-2 block text-sm">Category Place</span>
            <v-select
              label="name"
              :options="placeCategoryOptions"
              :reduce="category => category.id"
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
              v-model="payload.place_category_id"
            />
          </vs-col>

          <vs-col vs-w="12" class="mb-3">
            <vs-input
              class="w-full"
              label="Place Name (*)"
              v-model="payload.name"
            />
            <span class="block text-sm mt-2 text-danger">{{
              errors.first("name")
            }}</span>
          </vs-col>

          <vs-col vs-w="12" class="mt-3">
            <div class="vx-col w-full" @click.prevent="storeData">
              <vs-button class="mr-3 mb-2">Submit</vs-button>
            </div>
          </vs-col>
        </vs-row>
      </vs-col>
    </vs-row>
  </vx-card>
</template>

<script>
import axios from '@/axios'
import vSelect from 'vue-select'

// Store Module
import modulePlaceManagement from "@/store/place-management/modulePlaceManagement.js";

export default {
  name: "PlaceEdit",

  metaInfo: {
    title: "Edit Place",
    titleTemplate: "%s - Vancy Net",
    htmlAttrs: {
      lang: "en",
      amp: true
    }
  },

  components: {
    "v-select": vSelect
  },

  data: () => ({
    payload: {
      place_category_id: '',
      name: ""
    },

    loading: true,

    // Vselect options
    placeCategoryOptions: []
  }),

  computed: {
    decryptedID() {
      return this.$secure.decrypt(this.$route.params.id);
    }
  },

  methods: {
    getPlaceCategories() {
      axios
        .get("/place-category")
        .then(({ data: res }) => {
          this.placeCategoryOptions = res.data.map(category => {
            return {
              id: category.id,
              name: category.name
            }
          });
        })
        .catch(err => this.$catchErrorResponse(err));
    },

    getData() {
      this.$vs.loading({
        type: 'sound'
      })

      this.$store
        .dispatch("placeManagement/fetchPlace", this.decryptedID)
        .then(({ data: res }) => {
          this.payload.place_category_id = res.data.place_category.id;
          this.payload.name = res.data.name;
          this.url = res.data.icon == null ? null : res.data.icon;
        })
        .catch(err => this.$catchErrorResponse(err))
        .finally(() => {
          this.loading = false
          this.$vs.loading.close()
        })
    },

    storeData() {
      const formData = new FormData();
      // set formData
      formData.set('place_category_id', this.payload.place_category_id)
      formData.set("name", this.payload.name);
      formData.set("_method", "PUT");
      if (this.payload.icon !== "") {
        formData.set("icon", this.payload.icon);
      }

      this.$store
        .dispatch("placeManagement/updatePlace", {
          id: this.decryptedID,
          body: formData
        })
        .then(({ data: res }) => {
          this.$vs.notify({
            title: "Successful",
            text: res.msg_status,
            iconPack: "feather",
            icon: "icon-check-circle",
            color: "success"
          });

          this.$router.push("/places");
        })
        .catch(err => {
          this.$catchErrorResponse(err)
        });
    }
  },

  created() {
    if (!modulePlaceManagement.isRegistered) {
      this.$store.registerModule("placeManagement", modulePlaceManagement);
      modulePlaceManagement.isRegistered = true;
    }

    this.getPlaceCategories()
    this.getData()
  }
};
</script>

<style></style>
