import axios from '@/axios.js'

export default {
  fetchPlaces ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/place')
        .then(({data: response}) => {
          commit('SET_PLACES', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  fetchPlace (context, placeId) {
    return new Promise((resolve, reject) => {
      axios.get(`/place/${placeId}`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  storePlace (context, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/place', payload)
           .then(res => {
             resolve(res)
           })
           .catch(err => {
             reject(err)
           })
    })
  },

  updatePlace (context, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`/place/${payload.id}`, payload.body)
           .then(res => {
             resolve(res)
           })
           .catch(err => {
             reject(err)
           })
    })
  },

  destroyPlace ({ commit }, placeId) {
    return new Promise((resolve, reject) => {
      axios.delete(`place/${placeId}`)
        .then((response) => {
          commit('REMOVE_RECORD', placeId)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  }
}
